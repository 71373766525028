import React, {useState} from 'react';
import CloseButton from './CloseButton';
import './BetaTag.css';

function BetaTag(props) {
    
    const [formVisible, setFormVisible] = useState(false);

    return (
        
            <div className={"BetaTag"}>

                    <div className='form-button' onClick={() => setFormVisible(true)}>
                        <h2>Beta</h2>
                    </div> 

                    {formVisible &&

                        <div className={"overlay"}>
                            <div className='content'>
                                <div className='header'> <CloseButton  onClick={() => setFormVisible(false)}/> </div>
                                <div className='body'>
                                    <h1 className='padding-medium' >Beta testing</h1>
                                    
                                    <p>As a beta tester, you are using features that are still being tested and refined.</p>
                                    
                                    <p>To report bugs, issues and to provide feedback and suggestions, please complete the feedback form or
                                        send an email to support.signalbox@thetrainline.com. </p>
                                </div>
                                
                            </div>
                        </div> 
                    }
            
           
            </div>
            
    
    );
}

export default BetaTag;

